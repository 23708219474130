interface PartnerData {
  siteName: string;
  siteUrl: string;
}

const milfPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Best Fetish Sites",
    siteUrl: "https://thebestfetishsites.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Whitelist",
    siteUrl: "https://pornwhitelist.com/",
  },
 {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  }
]

const indianPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Best Fetish Sites",
    siteUrl: "https://thebestfetishsites.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Whitelist",
    siteUrl: "https://pornwhitelist.com/",
  }
]

const latinaPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Best Fetish Sites",
    siteUrl: "https://thebestfetishsites.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Whitelist",
    siteUrl: "https://pornwhitelist.com/",
  }
]

const bbwPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Best Fetish Sites",
    siteUrl: "https://thebestfetishsites.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Whitelist",
    siteUrl: "https://pornwhitelist.com/",
  }
]

const ebonyPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Best Fetish Sites",
    siteUrl: "https://thebestfetishsites.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Whitelist",
    siteUrl: "https://pornwhitelist.com/",
  },
 {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  }
]

const shemalePartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "AI Generator Porn",
    siteUrl: "https://aigeneratorporn.com/",
  },
  {
    siteName: "AINSFWTools",
    siteUrl: "https://www.ainsfwtools.com/tool/ai-shemale-generator/",
  },
  {
    siteName: "ThePornLinks.com",
    siteUrl: "https://thepornlinks.com/",
  },
  {
    siteName: "PornTourist - Best Porn Sites",
    siteUrl: "http://porntourist.com/?ref=createaishemale.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Map",
    siteUrl: "https://thepornmap.com/",
  },
  {
    siteName: "Hentaizilla",
    siteUrl: "https://hentaizilla.com/shemale-hentai/",
  },
  {
    siteName: "Best Premium Pornsite",
    siteUrl: "https://bestpremiumpornsite.com/",
  },
  {
    siteName: "AI Nude Generators",
    siteUrl: "https://ainudegenerators.com/",
  },
  {
    siteName: "NSFWAIBay",
    siteUrl: "https://nsfwaibay.com/",
  },
  {
    siteName: "XXX",
    siteUrl: "https://www.xxx.xxx/",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "Pornsitesguide.com",
    siteUrl: "https://pornsitesguide.com/",
  },
  {
    siteName: "Pornaigeneration.com",
    siteUrl: "https://pornaigeneration.com/",
  },
  {
    siteName: "Theporngoat.com",
    siteUrl: "https://theporngoat.com/",
  },
  {
    siteName: "Tube Porn List",
    siteUrl: "https://tubepornlist.com/",
  },
  {
    siteName: "Top Porn Sites",
    siteUrl: "https://toplist18.com/",
  },
  {
    siteName: "AnyPornSites",
    siteUrl: "https://anypornsites.com/",
  },
  {
    siteName: "iPornList",
    siteUrl: "https://ipornlist.com/",
  },
  {
    siteName: "MainPornSites",
    siteUrl: "https://mainpornsites.com/",
  },
  {
    siteName: "Porn List 18+",
    siteUrl: "https://Pornlist18.Com",
  },
  {
    siteName: "BeyondAIPorn",
    siteUrl: "https://beyondaiporn.com/",
  },
  {
    siteName: "The Porn Blender",
    siteUrl: "https://www.thepornblender.com",
  },
  {
    siteName: "Trans Porn Sites",
    siteUrl: "https://www.transpornsites.com/",
  },
  {
    siteName: "Gay Porn Menu",
    siteUrl: "https://www.gaypornmenu.com/",
  },
  {
    siteName: "AI Gay Porn",
    siteUrl: " https://gaymeister.com/ai-gay-porn/",
  },
];

const furryPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "AI Generator Porn",
    siteUrl: "https://aigeneratorporn.com/",
  },
  {
    siteName: "AINSFWTools",
    siteUrl: "https://www.ainsfwtools.com/tool/furry-porn-ai-generator/",
  },
  {
    siteName: "ThePornLinks.com",
    siteUrl: "https://thepornlinks.com/",
  },
  {
    siteName: "PornTourist - Best Porn Sites",
    siteUrl: "http://porntourist.com/?ref=createaifurry.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Map",
    siteUrl: "https://thepornmap.com/",
  },
  {
    siteName: "Hentaizilla",
    siteUrl: "https://hentaizilla.com/furry-hentai/",
  },
  {
    siteName: "Best Premium Pornsite",
    siteUrl: "https://bestpremiumpornsite.com/",
  },
  {
    siteName: "AI Nude Generators",
    siteUrl: "https://ainudegenerators.com/",
  },
  {
    siteName: "NSFWAIBay",
    siteUrl: "https://nsfwaibay.com/",
  },
  {
    siteName: "XXX",
    siteUrl: "https://www.xxx.xxx/",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "Pornsitesguide.com",
    siteUrl: "https://pornsitesguide.com/",
  },
  {
    siteName: "Pornaigeneration.com",
    siteUrl: "https://pornaigeneration.com/",
  },
  {
    siteName: "Theporngoat.com",
    siteUrl: "https://theporngoat.com/",
  },
  {
    siteName: "Tube Porn List",
    siteUrl: "https://tubepornlist.com/",
  },
  {
    siteName: "Top Porn Sites",
    siteUrl: "https://toplist18.com/",
  },
  {
    siteName: "AnyPornSites",
    siteUrl: "https://anypornsites.com/",
  },
  {
    siteName: "iPornList",
    siteUrl: "https://ipornlist.com/",
  },
  {
    siteName: "MainPornSites",
    siteUrl: "https://mainpornsites.com/",
  },
  {
    siteName: "Porn List 18+",
    siteUrl: "https://Pornlist18.Com",
  },
  {
    siteName: "BeyondAIPorn",
    siteUrl: "https://beyondaiporn.com/",
  },
  {
    siteName: "The Porn Blender",
    siteUrl: "https://www.thepornblender.com",
  },
  {
    siteName: "Trans Porn Sites",
    siteUrl: "https://www.transpornsites.com/",
  },
  {
    siteName: "Gay Porn Menu",
    siteUrl: "https://www.gaypornmenu.com/",
  },
];

const gayPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "AI Generator Porn",
    siteUrl: "https://aigeneratorporn.com/",
  },
  {
    siteName: "Mypornadviser.com",
    siteUrl: "https://mypornadviser.com/",
  },
  {
    siteName: "Bestpornsubscriptions.com",
    siteUrl: "https://bestpornsubscriptions.com/",
  },
  {
    siteName: "Bestgaysites.com",
    siteUrl: "https://bestgaysites.com/",
  },
  {
    siteName: "AINSFWTools",
    siteUrl: "https://www.ainsfwtools.com/tool/gay-ai-porn-generator/",
  },
  {
    siteName: "ThePornLinks.com",
    siteUrl: "https://thepornlinks.com/",
  },
  {
    siteName: "PornTourist - Best Porn Sites",
    siteUrl: "http://porntourist.com/?ref=createaigayporn.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Map",
    siteUrl: "https://thepornmap.com/",
  },
  {
    siteName: "Hentaizilla",
    siteUrl: "https://hentaizilla.com/yaoi-hentai",
  },
  {
    siteName: "Best Premium Pornsite",
    siteUrl: "https://bestpremiumpornsite.com/",
  },
  {
    siteName: "AI Nude Generators",
    siteUrl: "https://ainudegenerators.com/",
  },
  {
    siteName: "NSFWAIBay",
    siteUrl: "https://nsfwaibay.com/",
  },
  {
    siteName: "XXX",
    siteUrl: "https://www.xxx.xxx/",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "Pornsitesguide.com",
    siteUrl: "https://pornsitesguide.com/",
  },
  {
    siteName: "Pornaigeneration.com",
    siteUrl: "https://pornaigeneration.com/",
  },
  {
    siteName: "Theporngoat.com",
    siteUrl: "https://theporngoat.com/",
  },
  {
    siteName: "Tube Porn List",
    siteUrl: "https://tubepornlist.com/",
  },
  {
    siteName: "Top Porn Sites",
    siteUrl: "https://toplist18.com/",
  },
  {
    siteName: "AnyPornSites",
    siteUrl: "https://anypornsites.com/",
  },
  {
    siteName: "iPornList",
    siteUrl: "https://ipornlist.com/",
  },
  {
    siteName: "MainPornSites",
    siteUrl: "https://mainpornsites.com/",
  },
  {
    siteName: "Porn List 18+",
    siteUrl: "https://Pornlist18.Com",
  },
  {
    siteName: "BeyondAIPorn",
    siteUrl: "https://beyondaiporn.com/",
  },
  {
    siteName: "The Porn Blender",
    siteUrl: "https://www.thepornblender.com",
  },
  {
    siteName: "Trans Porn Sites",
    siteUrl: "https://www.transpornsites.com/",
  },
  {
    siteName: "Gay Porn Menu",
    siteUrl: "https://www.gaypornmenu.com/",
  },
  {
    siteName: "Best Gay AI Porn Sites",
    siteUrl: " https://gaymeister.com/ai-gay-porn/",
  },
];

const asianPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "XXX",
    siteUrl: "https://www.xxx.xxx/",
  },
  {
    siteName: "AI Generator Porn",
    siteUrl: "https://aigeneratorporn.com/",
  },
  {
    siteName: "AINSFWTools",
    siteUrl: "https://www.ainsfwtools.com/tool/ai-nude-asian-generator/",
  },
  {
    siteName: "ThePornLinks.com",
    siteUrl: "https://thepornlinks.com/",
  },
  {
    siteName: "PornTourist - Best Porn Sites",
    siteUrl: "http://porntourist.com/?ref=createaiasian.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Map",
    siteUrl: "https://thepornmap.com/",
  },
  {
    siteName: "Best Premium Pornsite",
    siteUrl: "https://bestpremiumpornsite.com/",
  },
  {
    siteName: "AI Nude Generators",
    siteUrl: "https://ainudegenerators.com/",
  },
  {
    siteName: "NSFWAIBay",
    siteUrl: "https://nsfwaibay.com/",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "Pornsitesguide.com",
    siteUrl: "https://pornsitesguide.com/",
  },
  {
    siteName: "Pornaigeneration.com",
    siteUrl: "https://pornaigeneration.com/",
  },
  {
    siteName: "Theporngoat.com",
    siteUrl: "https://theporngoat.com/",
  },
  {
    siteName: "Tube Porn List",
    siteUrl: "https://tubepornlist.com/",
  },
  {
    siteName: "Top Porn Sites",
    siteUrl: "https://toplist18.com/",
  },
  {
    siteName: "AnyPornSites",
    siteUrl: "https://anypornsites.com/",
  },
  {
    siteName: "iPornList",
    siteUrl: "https://ipornlist.com/",
  },
  {
    siteName: "MainPornSites",
    siteUrl: "https://mainpornsites.com/",
  },
  {
    siteName: "Porn List 18+",
    siteUrl: "https://Pornlist18.Com",
  },
  {
    siteName: "BeyondAIPorn",
    siteUrl: "https://beyondaiporn.com/",
  },
  {
    siteName: "The Porn Blender",
    siteUrl: "https://www.thepornblender.com",
  },
  {
    siteName: "Trans Porn Sites",
    siteUrl: "https://www.transpornsites.com/",
  },
  {
    siteName: "Gay Porn Menu",
    siteUrl: "https://www.gaypornmenu.com/",
  },
];

const hentaiPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "Hentai Fucking",
    siteUrl: "https://hentaifucking.com/",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "AINSFWTools",
    siteUrl: "https://www.ainsfwtools.com/tool/create-hentai/",
  },
  {
    siteName: "AI Generator Porn",
    siteUrl: "https://aigeneratorporn.com/",
  },
  {
    siteName: "ThePornLinks.com",
    siteUrl: "https://thepornlinks.com/",
  },
  {
    siteName: "PornTourist - Best Porn Sites",
    siteUrl: "http://porntourist.com/?ref=createhentai.com",
  },
  {
    siteName: "thepornlist.net",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "The Porn Map",
    siteUrl: "https://thepornmap.com/",
  },
  {
    siteName: "Hentaizilla",
    siteUrl: "https://hentaizilla.com/ai-hentai-generators/",
  },
  {
    siteName: "Best Premium Pornsite",
    siteUrl: "https://bestpremiumpornsite.com/",
  },
  {
    siteName: "AI Nude Generators",
    siteUrl: "https://ainudegenerators.com/",
  },
  {
    siteName: "NSFWAIBay",
    siteUrl: "https://nsfwaibay.com/",
  },
  {
    siteName: "XXX",
    siteUrl: "https://www.xxx.xxx/",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "txscz.com",
    siteUrl: "https://txscz.com",
  },
  {
    siteName: "Pornsitesguide.com",
    siteUrl: "https://pornsitesguide.com/",
  },
  {
    siteName: "Pornaigeneration.com",
    siteUrl: "https://pornaigeneration.com/",
  },
  {
    siteName: "Theporngoat.com",
    siteUrl: "https://theporngoat.com/",
  },
  {
    siteName: "Tube Porn List",
    siteUrl: "https://tubepornlist.com/",
  },
  {
    siteName: "Top Porn Sites",
    siteUrl: "https://toplist18.com/",
  },
  {
    siteName: "AnyPornSites",
    siteUrl: "https://anypornsites.com/",
  },
  {
    siteName: "iPornList",
    siteUrl: "https://ipornlist.com/",
  },
  {
    siteName: "MainPornSites",
    siteUrl: "https://mainpornsites.com/",
  },
  {
    siteName: "Porn List 18+",
    siteUrl: "https://Pornlist18.Com",
  },
  {
    siteName: "BeyondAIPorn",
    siteUrl: "https://beyondaiporn.com/",
  },
  {
    siteName: "The Porn Blender",
    siteUrl: "https://www.thepornblender.com",
  },
  {
    siteName: "Trans Porn Sites",
    siteUrl: "https://www.transpornsites.com/",
  },
  {
    siteName: "Gay Porn Menu",
    siteUrl: "https://www.gaypornmenu.com/",
  },
  // Add more partners as needed
];

const pornPartners: PartnerData[] = [
  {
    siteName: "The Porn Dude",
    siteUrl: "https://theporndude.com",
  },
  {
    siteName: "AI Generator Porn",
    siteUrl: "https://aigeneratorporn.com/",
  },
  {
    siteName: "Mypornadviser.com",
    siteUrl: "https://mypornadviser.com/",
  },
  {
    siteName: "Bestpornsubscriptions.com",
    siteUrl: "https://bestpornsubscriptions.com/",
  },
  {
    siteName: "Bestgaysites.com",
    siteUrl: "https://bestgaysites.com/",
  },
  {
    siteName: "AINSFWTools",
    siteUrl: "https://www.ainsfwtools.com/tool/createporn-ai/",
  },
  {
    siteName: "PaidPornSitesWorld",
    siteUrl: "https://paidpornsitesworld.com/",
  },
  {
    siteName: "Theporndork.com",
    siteUrl: "https://porndork.com",
  },
  {
    siteName: "Pornordo",
    siteUrl: "https://pornordo.com/",
  },
  {
    siteName: "Tube Porn List",
    siteUrl: "https://tubepornlist.com/",
  },
  {
    siteName: "The Best Fetish Sites",
    siteUrl: "https://thebestfetishsites.com",
  },
  {
    siteName: "Nichepornsites",
    siteUrl: "https://nichepornsites.com/",
  },
  {
    siteName: "Theporntourist",
    siteUrl: "https://porntourist.com",
  },
  {
    siteName: "The Porn Map",
    siteUrl: "https://thepornmap.com/",
  },
  {
    siteName: "Best Premium Pornsite",
    siteUrl: "https://bestpremiumpornsite.com/",
  },
  {
    siteName: "AI Nude Generators",
    siteUrl: "https://ainudegenerators.com/",
  },
  {
    siteName: "NSFWAIBay",
    siteUrl: "https://nsfwaibay.com/",
  },
  {
    siteName: "Premiumpornlist",
    siteUrl: "https://www.premiumpornlist.com/",
  },
  {
    siteName: "XGLUZ",
    siteUrl: "https://xgluz.com/ai-porn-sites/",
  },
  {
    siteName: "Hentaizilla",
    siteUrl: "https://hentaizilla.com/ai-hentai-generators/",
  },
  {
    siteName: "Foundr.AI",
    siteUrl: "https://foundr.ai/embed/createporn-com",
  },
    {
    siteName: "XXX",
    siteUrl: "https://www.xxx.xxx/",
  },
  {
    siteName: "The Porn Couch",
    siteUrl: "https://theporncouch.com",
  },
  {
    siteName: "Ultimate Premium XXX Sites List",
    siteUrl: "https://bestpremiumpornsites.com",
  },
  {
    siteName: "The Porn List",
    siteUrl: "https://www.thepornlist.net/",
  },
  {
    siteName: "Best Fetishes",
    siteUrl: "https://www.bestfetishes.com/",
  },
  {
    siteName: "ThePornLinks.com",
    siteUrl: "https://thepornlinks.com/",
  },
  {
    siteName: "The Porn Whitelist",
    siteUrl: "https://pornwhitelist.com/",
  },
  {
    siteName: "Thepornbest.com",
    siteUrl: "https://www.thepornbest.com/",
  },
  {
    siteName: "Best Porn Sites",
    siteUrl: "https://www.thepornbin.com/",
  },
  {
    siteName: "ThePornData",
    siteUrl: "https://theporndata.com/",
  },
  {
    siteName: "Ai Generators Porn",
    siteUrl: "https://aigeneratorsporn.com/",
  },
  {
    siteName: "EXXXTRA - Top Porn Sites List",
    siteUrl: "http://exxxtra.net/",
  },
  {
    siteName: "The Porn Blender",
    siteUrl: "https://www.thepornblender.com/",
  },
  {
    siteName: "The Porn Cat",
    siteUrl: "https://theporncat.com/",
  },
  {
    siteName: "5 Star Porn Sites",
    siteUrl: "http://fivestarpornsites.com/",
  },
  {
    siteName: "Findbare.com",
    siteUrl: "https://findbare.com/",
  },
  {
    siteName: "Lemonsight",
    siteUrl: "https://lemonsight.com/categories/nfsw/",
  },
  {
    siteName: "ThePorncall",
    siteUrl: "https://www.theporncall.com",
  },
  {
    siteName: "Porn List 18+",
    siteUrl: "https://Pornlist18.Com",
  },
  {
    siteName: "List of Porn Sites",
    siteUrl: "https://pornsites.xxx/",
  },
  {
    siteName: "Porn List 18+",
    siteUrl: "https://pornlist18.com/",
  },
  {
    siteName: "Pornrangers",
    siteUrl:
      " https://pornrangers.com/?utm_source=createporn.com&utm_medium=Referral",
  },
  {
    siteName: "YouPornList",
    siteUrl: "https://www.youpornlist.com/",
  },
  {
    siteName: "AnyPornSites",
    siteUrl: "https://anypornsites.com/",
  },
  {
    siteName: "Top Porn Sites",
    siteUrl: "https://toplist18.com/",
  },
  {
    siteName: "iPornList",
    siteUrl: "https://ipornlist.com/",
  },
  {
    siteName: "MainPornSites",
    siteUrl: "https://mainpornsites.com/",
  },
  {
    siteName: "All Porn Sites",
    siteUrl: "https://allpornsites.net/",
  },
  {
    siteName: "Best 3D New Porn",
    siteUrl: "https://topcartoonpornsites.com/3d-porn-sites/",
  },
  {
    siteName: "Top Premium Porn",
    siteUrl: "https://toppremiumporn.com/",
  },
  {
    siteName: "AI Generation Porn",
    siteUrl: "http://aigenerationporn.com/",
  },
  {
    siteName: "Musclegirldirectory.com",
    siteUrl: "https://www.musclegirldirectory.com/",
  },
  {
    siteName: "Porn Pay Sites",
    siteUrl: "https://pornpaysites.net/",
  },
  {
    siteName: "Pornsitesguide.com",
    siteUrl: "https://pornsitesguide.com/",
  },
  {
    siteName: "Pornaigeneration.com",
    siteUrl: "https://pornaigeneration.com/",
  },
  {
    siteName: "Theporngoat.com",
    siteUrl: "https://theporngoat.com/",
  },
  {
    siteName: "BeyondAIPorn",
    siteUrl: "https://beyondaiporn.com/",
  },
  {
    siteName: "Trans Porn Sites",
    siteUrl: "https://www.transpornsites.com/",
  },
  {
    siteName: "Gay Porn Menu",
    siteUrl: "https://www.gaypornmenu.com/",
  },
  {
    siteName: "thepornlogs.com",
    siteUrl: "https://thepornlogs.com/",
  },
  {
    siteName: "Porndork.com",
    siteUrl: "https://porndork.com/",
  },
  {
    siteName: "freeporned",
    siteUrl: "https://freeporned.com/",
  },
  {
    siteName: "AdultList",
    siteUrl: "https://adultlist.net/",
  },
  {
    siteName: "AItoolnet",
    siteUrl: "https://www.aitoolnet.com",
  },
  {
    siteName: "Datingtools.AI",
    siteUrl: "https://datingtools.ai/",
  },
  {
    siteName: "My Porn Dir",
    siteUrl: "https://myporndir.com/",
  },
  {
    siteName: "Top Class Porn Sites",
    siteUrl: "https://topClassLinks/?ref=createporn/",
  },
  {
    siteName: "Best Porn 2024",
    siteUrl: "https://buypremiumporn.com/",
  },
  {
    siteName: "Live Free Adult Cams",
    siteUrl: "https://babestationcams.com/",
  },
  {
    siteName: "Dominatrix Cams",
    siteUrl: "https://femdomlive.com/dominatrix-cams/",
  },
  {
    siteName: "Fetish Chat Rooms",
    siteUrl: " https://fetishcams-live.com/fetish-chat-rooms/",
  },
  {
    siteName: "Nairobi Escorts",
    siteUrl: "https://www.exotickenya.com/escorts-from/nairobi-escorts/"
  },
  {
    siteName: "Muscle Girl Porn",
    siteUrl: "https://musclegirlflix.com/"
  },
  {
    siteName: "NSFW AI Directory",
    siteUrl: "https://bestnsfw.ai/"
  }

  // Add more partners as needed
];

const siblingPornSites: Array<{ name: string; url: string }> = [
  { name: "AIPorn", url: "https://www.createporn.com" },
  { name: "AIShemale", url: "https://www.createaishemale.com" },
  { name: "AIHentai", url: "https://www.createhentai.com" },
  { name: "AIAsian", url: "https://www.createaiasian.com" },
  { name: "AIGayPorn", url: "https://www.createaigayporn.com" },
  { name: "AIFurry", url: "https://www.createaifurry.com" },
  { name: "AIEbony", url: "https://www.createaiebony.com" },
  { name: "AIBBW", url: "https://www.createaibbw.com" },
  { name: "AILatina", url: "https://www.createailatina.com" },
  { name: "AIIndian", url: "https://www.createaiindian.com" },
  { name: "AIMilf", url: "https://www.createaimilf.com" },
  // { name: "PornGames", url: "https://www.nutaku.net/signup/landing/project-qt/2/?ats=eyJhIjo5OTg3NzIsImMiOjYxNzczMzM2LCJuIjoxLCJzIjoxLCJlIjo5MjM4LCJwIjoyfQ==" },
  // TODO: enable these sites
];

const siblingHentaiSites: Array<{ name: string; url: string }> = [
  { name: "AIPorn", url: "https://www.createporn.com" },
  { name: "AIHentai", url: "https://www.createhentai.com" },
  { name: "AIShemale", url: "https://www.createaishemale.com" },
  { name: "AIAsian", url: "https://www.createaiasian.com" },
  { name: "AIGayPorn", url: "https://www.createaigayporn.com" },
  { name: "AIFurry", url: "https://www.createaifurry.com" },
  { name: "AIEbony", url: "https://www.createaiebony.com" },
  { name: "AIBBW", url: "https://www.createaibbw.com" },
  { name: "AILatina", url: "https://www.createailatina.com" },
  { name: "AIIndian", url: "https://www.createaiindian.com" },
  { name: "AIMilf", url: "https://www.createaimilf.com" },
  // { name: "PornGames", url: "https://www.nutaku.net/signup/landing/project-qt/2/?ats=eyJhIjo5OTg3NzIsImMiOjYxNzczMzM2LCJuIjoxLCJzIjoxLCJlIjo5MjM4LCJwIjoyfQ==" },
  // TODO: enable these sites
];

function removeSiteByName(
  sitesArray: Array<{ name: string; url: string }>,
  siteName: string,
) {
  return sitesArray.filter((site) => site.name !== siteName);
}

export const siteConfigurations = [
  {
    origin: 0,
    category: "Porn",
    siteName: "CreatePorn",
    siteNameFull: "CreatePorn.com",
    siteUrl: "https://www.createporn.com",
    sibilingSites: removeSiteByName(siblingPornSites, "CreatePorn"),
    shortLogo: "https://cdn6.imgpog.com/newLogoShort.svg",
    shortLogoWidth: 30,
    shortLogoHeight: 17,
    fullLogo: "https://cdn6.imgpog.com/newLogoFull.svg",
    fullLogoWidth: 190,
    fullLogoHeight: 18.465,
    mobileLogo: "https://cdn6.imgpog.com/newLogoMobile.svg",
    mobileLogoWidth: 140,
    mobileLogoHeight: 16.165,
    mainSEOTitle: "sites.mainSEOTitle",
    postSEOTitle: "sites.postSEOTitle",
    create: "sites.create",
    affiliatePartners: pornPartners,
    subredditName: "/r/createAIart",
    twitterLink: "https://x.com/CreateAIArts"
  },
  {
    origin: 1,
    category: "GayPorn",
    siteName: "CreateGayPorn",
    siteNameFull: "CreateAIGayPorn.com",
    siteUrl: "https://www.createaigayporn.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIGayPorn"),
    shortLogo: "https://cdn6.imgpog.com/boyfriendLogoShort.svg",
    shortLogoWidth: 20,
    shortLogoHeight: 20,
    fullLogo: "https://cdn6.imgpog.com/boyfriendLogoFull.svg",
    fullLogoWidth: 220,
    fullLogoHeight: 20.63,
    mobileLogo: "https://cdn6.imgpog.com/boyfriendLogoMobile.svg",
    mobileLogoWidth: 155,
    mobileLogoHeight: 16.75,
    mainSEOTitle: "sites.mainSEOTitleg",
    postSEOTitle: "sites.postSEOTitleg",
    create: "sites.createg",
    affiliatePartners: gayPartners,
    subredditName: "/r/createAIgayporn",
    twitterLink: "https://x.com/CreateAIGayPorn"
  },
  {
    origin: 2,
    category: "Hentai",
    siteName: "CreateHentai",
    siteNameFull: "CreateHentai.com",
    siteUrl: "https://www.createhentai.com",
    sibilingSites: removeSiteByName(siblingHentaiSites, "AIHentai"),
    shortLogo: "https://cdn6.imgpog.com/hentaiLogoShort.webp",
    shortLogoWidth: 30,
    shortLogoHeight: 34.39,
    fullLogo: "https://cdn6.imgpog.com/hentaiLogoFull.webp",
    fullLogoWidth: 200,
    fullLogoHeight: 36.43,
    mobileLogo: "https://cdn6.imgpog.com/hentaiLogoMobile.webp",
    mobileLogoWidth: 140,
    mobileLogoHeight: 29.77,
    mainSEOTitle: "sites.mainSEOTitleh",
    postSEOTitle: "sites.postSEOTitleh",
    create: "sites.createh",
    affiliatePartners: hentaiPartners,
    subredditName: "/r/createAIhentai",
    twitterLink: "https://x.com/CreateAIHentai"
  },
  {
    origin: 3,
    category: "Furry",
    siteName: "CreateAIFurry",
    siteNameFull: "CreateAIFurry.com",
    siteUrl: "https://www.createaifurry.com",
    sibilingSites: removeSiteByName(siblingHentaiSites, "AIFurry"),
    shortLogo: "https://cdn6.imgpog.com/furryLogoShort.svg",
    shortLogoWidth: 25,
    shortLogoHeight: 31.95,
    fullLogo: "https://cdn6.imgpog.com/furryLogoFull.svg",
    fullLogoWidth: 200,
    fullLogoHeight: 22.47,
    mobileLogo: "https://cdn6.imgpog.com/furryLogoMobile.svg",
    mobileLogoWidth: 140,
    mobileLogoHeight: 18.26,
    mainSEOTitle: "sites.mainSEOTitlef",
    postSEOTitle: "sites.postSEOTitlef",
    create: "sites.createf",
    affiliatePartners: furryPartners,
    subredditName: "/r/createAIfurry",
    twitterLink: "https://x.com/CreateAIFurry"
  },
  {
    origin: 4,
    category: "AsianPorn",
    siteName: "CreateAsian",
    siteNameFull: "CreateAIAsian.com",
    siteUrl: "https://www.createaiasian.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIAsian"),
    shortLogo: "https://cdn6.imgpog.com/asianLogoShort.svg",
    shortLogoWidth: 25,
    shortLogoHeight: 22.92,
    fullLogo: "https://cdn6.imgpog.com/asianLogoFull.svg",
    fullLogoWidth: 200,
    fullLogoHeight: 21.21,
    mobileLogo: "https://cdn6.imgpog.com/asianLogoMobile.svg",
    mobileLogoWidth: 140,
    mobileLogoHeight: 17.69,
    mainSEOTitle: "sites.mainSEOTitlea",
    postSEOTitle: "sites.postSEOTitlea",
    create: "sites.create", //intended without a, other wise too much text will be on this button
    affiliatePartners: asianPartners,
    subredditName: "/r/createAIasian",
    twitterLink: "https://x.com/CreateAIAsian"
  },
  {
    origin: 5,
    category: "ShemalePorn",
    siteName: "CreateShemale",
    siteNameFull: "CreateAIShemale.com",
    siteUrl: "https://www.createaishemale.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIShemale"),
    shortLogo: "https://cdn6.imgpog.com/shemaleLogoShort.svg",
    shortLogoWidth: 25,
    shortLogoHeight: 20.46,
    fullLogo: "https://cdn6.imgpog.com/shemaleLogoFull.svg",
    fullLogoWidth: 220,
    fullLogoHeight: 18.51,
    mobileLogo: "https://cdn6.imgpog.com/shemaleLogoMobile.svg",
    mobileLogoWidth: 155,
    mobileLogoHeight: 13.62,
    mainSEOTitle: "sites.mainSEOTitles",
    postSEOTitle: "sites.postSEOTitles",
    create: "sites.creates",
    affiliatePartners: shemalePartners,
    subredditName: "/r/createAIshemale",
    twitterLink: "https://x.com/CreateAIShemale"
  },
  {
    origin: 6,
    category: "EbonyPorn",
    siteName: "CreateEbony",
    siteNameFull: "CreateAIEbony.com",
    siteUrl: "https://www.createaiebony.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIEbony"),
    shortLogo: "https://cdn6.imgpog.com/ebonyLogoShort.svg",
    shortLogoWidth: 20,
    shortLogoHeight: 17.27,
    fullLogo: "https://cdn6.imgpog.com/ebonyLogoFull.svg",
    fullLogoWidth: 220,
    fullLogoHeight: 18.51,
    mobileLogo: "https://cdn6.imgpog.com/ebonyLogoMobile.svg",
    mobileLogoWidth: 160,
    mobileLogoHeight: 19.47,
    mainSEOTitle: "sites.mainSEOTitlee",
    postSEOTitle: "sites.postSEOTitlee",
    create: "sites.createe",
    affiliatePartners: ebonyPartners,
    subredditName: "/r/createAIebony",
    twitterLink: "https://x.com/CreateAIebony"
  },
  {
    origin: 7,
    category: "BBWPorn",
    siteName: "CreateBBW",
    siteNameFull: "CreateAIBBW.com",
    siteUrl: "https://www.createaibbw.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIBBW"),
    shortLogo: "https://cdn6.imgpog.com/bbwLogoShort.svg",
    shortLogoWidth: 20,
    shortLogoHeight: 20,
    fullLogo: "https://cdn6.imgpog.com/bbwLogoFull.svg",
    fullLogoWidth: 200,
    fullLogoHeight: 19.05,
    mobileLogo: "https://cdn6.imgpog.com/bbwLogoMobile.svg",
    mobileLogoWidth: 150,
    mobileLogoHeight: 17.19,
    mainSEOTitle: "sites.mainSEOTitleb",
    postSEOTitle: "sites.postSEOTitleb",
    create: "sites.createb",
    affiliatePartners: bbwPartners,
    subredditName: "/r/createAIbbw",
    twitterLink: "https://x.com/CreateAIbbw"
  },
  {
    origin: 8,
    category: "LatinaPorn",
    siteName: "CreateLatina",
    siteNameFull: "CreateAILatina.com",
    siteUrl: "https://www.createailatina.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AILatina"),
    shortLogo: "https://cdn6.imgpog.com/latinaLogoShort.svg",
    shortLogoWidth: 22.5,
    shortLogoHeight: 22.5,
    fullLogo: "https://cdn6.imgpog.com/latinaLogoFull.svg",
    fullLogoWidth: 200,
    fullLogoHeight: 20.38,
    mobileLogo: "https://cdn6.imgpog.com/latinaLogoMobile.svg",
    mobileLogoWidth: 155,
    mobileLogoHeight: 13.62,
    mainSEOTitle: "sites.mainSEOTitlel",
    postSEOTitle: "sites.postSEOTitlel",
    create: "sites.createl",
    affiliatePartners: latinaPartners,
    subredditName: "/r/createAIlatina",
    twitterLink: "https://x.com/CreateAIlatina"
  },
  {
    origin: 9,
    category: "IndianPorn",
    siteName: "CreateIndian",
    siteNameFull: "CreateAIIndian.com",
    siteUrl: "https://www.createaiindian.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIIndian"),
    shortLogo: "https://cdn6.imgpog.com/indianLogoShort.svg",
    shortLogoWidth: 18,
    shortLogoHeight: 18.5,
    fullLogo: "https://cdn6.imgpog.com/indianLogoFull.svg",
    fullLogoWidth: 200,
    fullLogoHeight: 20.5,
    mobileLogo: "https://cdn6.imgpog.com/indianLogoMobile.svg",
    mobileLogoWidth: 155,
    mobileLogoHeight: 13.62,
    mainSEOTitle: "sites.mainSEOTitlei",
    postSEOTitle: "sites.postSEOTitlei",
    create: "sites.createi",
    affiliatePartners: indianPartners,
    subredditName: "/r/createAIindian",
    twitterLink: "https://x.com/CreateAIindian"
  },
  {
    origin: 10,
    category: "MilfPorn",
    siteName: "CreateMilf",
    siteNameFull: "CreateAIMilf.com",
    siteUrl: "https://www.createaimilf.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIMilf"),
    shortLogo: "https://cdn6.imgpog.com/milfLogoShort.svg",
    shortLogoWidth: 25,
    shortLogoHeight: 20.46,
    fullLogo: "https://cdn6.imgpog.com/milfLogoFull.svg",
    fullLogoWidth: 200,
    fullLogoHeight: 20.5,
    mobileLogo: "https://cdn6.imgpog.com/milfLogoMobile.svg",
    mobileLogoWidth: 155,
    mobileLogoHeight: 13.62,
    mainSEOTitle: "sites.mainSEOTitlem",
    postSEOTitle: "sites.postSEOTitlem",
    create: "sites.createm",
    affiliatePartners: milfPartners,
    subredditName: "/r/createAImilf",
    twitterLink: "https://x.com/CreateAIMilf"
  },
];

const getSiteConfigurations = () => {
  switch (process.env.NEXT_PUBLIC_BASE_URL) {
    case "https://www.createporn.com":
      return siteConfigurations[0];
    case "https://www.createaigayporn.com":
      return siteConfigurations[1];
    case "https://www.createhentai.com":
      return siteConfigurations[2];
    case "https://www.createaifurry.com":
      return siteConfigurations[3];
    case "https://www.createaiasian.com":
      return siteConfigurations[4];
    case "https://www.createaishemale.com":
      return siteConfigurations[5];
    case "https://www.createaiebony.com":
      return siteConfigurations[6];
    case "https://www.createaibbw.com":
      return siteConfigurations[7];
    case "https://www.createailatina.com":
      return siteConfigurations[8];
    case "https://www.createaiindian.com":
      return siteConfigurations[9];
    case "https://www.createaimilf.com":
      return siteConfigurations[10]
    default:
      return siteConfigurations[0]; // Default case, can also be some default generators
  }
};

export const siteConfiguration = getSiteConfigurations();

export function isMainSite() {
  if (siteConfiguration.category === "Porn") {
    return true;
  }
  return false;
}

export function renderAnalyticsScript() {
  let websiteId = "";

  if (process.env.NEXT_PUBLIC_BASE_URL === "https://www.createporn.com") {
    websiteId = "403f8464-4e5b-473e-b6b3-a6d2ba43b2ef"; //'e227c990-e047-4713-8015-e8ed7d908ea0';
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createhentai.com"
  ) {
    websiteId = "92090319-75f4-4397-a5c7-860a59a86c5c"; //"6c04624a-f7f2-4c8b-a6f4-059458d0d6cb";
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createaigayporn.com"
  ) {
    websiteId = "37864502-f91b-4117-b7c9-90e490b12e4c"; //"429a45de-2f34-478a-a41a-d79453366fe8";
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createaifurry.com"
  ) {
    websiteId = "a7e8f7ed-2619-461a-9c68-4a76dbf12ee2"; //"d8bb4620-c466-418d-bf6c-623e9e07d1e4";
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createaiasian.com"
  ) {
    websiteId = "e1f023e2-7bf1-4b7d-b81e-dd1291c4f2a9"; //"c103379f-5df8-4013-b8c6-9c238904aeff";
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createaishemale.com"
  ) {
    websiteId = "9942496f-335b-4b3c-98bf-3c45cc6edb91"; //"119a3c84-c327-4393-b53e-9dcd1b7f4c34";
  }
  // TODO: Add websiteId for new sites
  else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createaiebony.com"
  ) {
    websiteId = ""
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createaibbw.com"
  ) {
    websiteId = ""
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createailatina.com"
  ) {
    websiteId = ""
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createaiindian.com"
  ) {
    websiteId = ""
  } else if (
    process.env.NEXT_PUBLIC_BASE_URL === "https://www.createaimilf.com"
  ) {
    websiteId = ""
  }

  return websiteId;
}
